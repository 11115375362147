import { Producer } from 'types/producer'
import { FaqQuestion } from 'types/faq'
import { Product } from 'types/product-data'

export type BoltGoProps = {
  producers: Producer[]
  questions: FaqQuestion[]
}

export type SectionProps = {
  isDark?: boolean
  startRegistration: (product: Product) => void
}

export enum SessionStorageKeys {
  FROM = 'fromPage',
  PRODUCT = 'chosenProduct'
}
