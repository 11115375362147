import { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import styles from 'features/bolt-go/BoltGo.module.scss'
import Icon from 'components/icon/Icon'
import { Electricity, Gas } from 'components/producers/icons/Icons'
import { Button } from '@boltenergy-be/design-system'
import { SectionProps } from 'features/bolt-go/types'
import { useTranslation } from 'next-i18next'
import { CustomerType, Product, ProductConfigOption } from 'types/product-data'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { SimulationType } from 'store/marketing/types'
import useWindowSize from 'hooks/useWindowSize'
import ToggleButtons from 'components/toggle-buttons/ToggleButtons'
import { SimulatePriceResponse } from 'features/simulation/types'
import { getSimulatedPrice } from 'utils/price'
import { determineSimulationUsage, requestAndSavePropositions } from 'features/simulation/utils'
import { formatAmount } from 'utils/format'
import { updateInput } from 'store/customer/slice'
import { InputGroupKeys, PersonalDataFieldKeys, SimulationFieldKeys } from 'store/customer/enums'
import { EnergyUsageGrade } from 'types/usages'
import { useRouter } from 'next/router'
import { useGetProductContentQuery } from 'store/api/cmsApi'
import { Language } from 'types/language'
import { PRODUCTS_CONFIG } from 'constants/products'
import { Heading } from '@boltenergy-be/design-system'

const OfferingSection: FC<SectionProps> = ({ isDark, startRegistration }) => {
  // Redux store
  const { simulatedPropositions } = useStoreSelector((store) => store.customer.propositions)
  const dispatch = useStoreDispatch()

  // Router
  const { locale } = useRouter()

  // Redux queries
  const { data: productsContent } = useGetProductContentQuery({ language: locale as Language })

  // i18n
  const { t } = useTranslation(['boltGo', 'common'])

  // Window size
  const { isMobile } = useWindowSize()

  // Local state
  const [showProduct, setShowProduct] = useState<Product>(Product.VARIABLE_OFFLINE)
  const [boltVariablePrice, setBoltVariablePrice] = useState<SimulatePriceResponse['price'] | undefined>(
    simulatedPropositions?.[Product.VARIABLE_OFFLINE]?.price
  )

  // Constants
  const needsGas = boltVariablePrice?.gas?.total !== 0

  /**
   * Triggered on pageload, fetches the bolt online price for express simulation
   */
  useEffect(() => {
    if (!boltVariablePrice) {
      getBoltVariablePrice()
    }
  }, [])

  /**
   * Get or calculate the bolt online price
   * @returns {Promise<SimulatePriceResponse['price']>}
   */
  const getBoltVariablePrice = async (): Promise<SimulatePriceResponse['price']> => {
    let price: SimulatePriceResponse['price']
    if (simulatedPropositions?.[Product.VARIABLE_OFFLINE]?.price) {
      price = getSimulatedPrice(Product.VARIABLE_OFFLINE, simulatedPropositions)
      setBoltVariablePrice(price)
      return
    }

    dispatch(
      updateInput({ group: InputGroupKeys.SIMULATION, key: SimulationFieldKeys.CHOSEN_SIMULATION_TYPE, value: SimulationType.EXPRESS })
    )
    dispatch(updateInput({ group: InputGroupKeys.PERSONAL_DATA, key: PersonalDataFieldKeys.NEEDS_GAS, value: true }))
    dispatch(updateInput({ group: InputGroupKeys.SIMULATION, key: SimulationFieldKeys.USAGE_GRADE, value: EnergyUsageGrade.AVERAGE }))
    dispatch(updateInput({ group: InputGroupKeys.SIMULATION, key: SimulationFieldKeys.USAGE, value: determineSimulationUsage() }))

    await requestAndSavePropositions({
      onSuccess: (availablePropositions) => (price = getSimulatedPrice(Product.VARIABLE_OFFLINE, availablePropositions)),
      products: [Product.VARIABLE_OFFLINE]
    })

    setBoltVariablePrice(price)
  }

  return (
    <section id="offering-section" className={classNames('section', { ['is-dark']: isDark }, styles['offering-section'])}>
      <div className="container">
        <div className={styles.intro}>
          <Heading as="h2" variant="h3">
            {t('offering.title', 'Kies het tarief dat bij jou past')}
          </Heading>
          <p>
            {t(
              'offering.description',
              'Wij bieden een eenvoudig variabel tarief. De energie- en nettarieven blijven voor alle opties hetzelfde. Alleen de platformkost voor Bolt varieert, en of je een maandelijks of jaarlijks voorschot betaalt.'
            )}
          </p>
        </div>

        {isMobile && (
          <ToggleButtons
            onClick={(product) => setShowProduct(product)}
            active={showProduct}
            options={[
              {
                label: `Bolt ${productsContent?.[Product.VARIABLE_OFFLINE]?.name}`,
                value: Product.VARIABLE_OFFLINE
              },
              {
                label: `Bolt ${productsContent?.[Product.GO]?.name}`,
                value: Product.GO
              }
            ]}
          />
        )}

        {/* ONLINE */}
        {(!isMobile || (isMobile && showProduct === Product.VARIABLE_OFFLINE)) && (
          <div className={classNames(styles.offering, { [styles.dark]: !isDark })}>
            <Heading as="h3" variant="h4">
              Bolt {productsContent?.[Product.VARIABLE_OFFLINE]?.name}
            </Heading>

            <ul className={styles.advantages}>
              <li className={styles.advantage}>
                <Icon name="checkJagged" />
                {t('offerings.online.advantage.one', 'Duurzame en lokale stroom')}
              </li>
              <li className={styles.advantage}>
                <Icon name="checkJagged" />
                {t('offerings.online.advantage.two', 'Eenvoudig variabel tarief')}
              </li>
              <li className={styles.advantage}>
                <Icon name="checkJagged" />
                {t('offerings.advantage.platformCost', 'Platformkost aan € {{ price }} / maand', {
                  price: formatAmount(
                    PRODUCTS_CONFIG[Product.VARIABLE_OFFLINE][ProductConfigOption.PLATFORM_COST][CustomerType.RESIDENTIAL]
                  )
                })}
              </li>
            </ul>

            <hr />

            <div>
              <Heading as="h4" variant="h6" className="mb-200">
                {t('offerings.online.subtitle', 'Maandelijks voorschot')}
              </Heading>
              <p>
                {t(
                  'offerings.online.description',
                  'Geschat op basis van het gemiddeld energieverbruik van een Belgisch huishouden en de toekomstige marktprijzen voor energie.'
                )}
              </p>
            </div>

            <div className={styles.estimations}>
              <div className={styles.estimation}>
                <Electricity />{' '}
                {boltVariablePrice?.electricity ? (
                  <data value={boltVariablePrice?.electricity?.total}>€ {formatAmount(boltVariablePrice?.electricity?.total)}</data>
                ) : (
                  '?'
                )}
              </div>

              {needsGas && (
                <div className={styles.estimation}>
                  <Gas />{' '}
                  {boltVariablePrice?.gas ? (
                    <data value={boltVariablePrice?.gas?.total}>€ {formatAmount(boltVariablePrice?.gas?.total)}</data>
                  ) : (
                    '?'
                  )}
                </div>
              )}
            </div>

            <Button onClick={() => startRegistration(Product.VARIABLE_OFFLINE)}>
              {t('offerings.cta', 'Stap over met Bolt {{ product }}', {
                product: productsContent?.[Product.VARIABLE_OFFLINE]?.name
              })}
            </Button>
          </div>
        )}

        {/* GO */}
        {(!isMobile || (isMobile && showProduct === Product.GO)) && (
          <div className={classNames(styles.offering, { [styles.dark]: !isDark })}>
            <Heading as="h3" variant="h4">
              Bolt {productsContent?.[Product.GO]?.name}
            </Heading>

            <ul className={styles.advantages}>
              <li className={styles.advantage}>
                <Icon name="checkJagged" />
                {t('offerings.go.advantage.one', 'Duurzame en lokale stroom')}
              </li>
              <li className={styles.advantage}>
                <Icon name="checkJagged" />
                {t('offerings.go.advantage.two', 'Eenvoudig variabel tarief')}
              </li>
              <li className={styles.advantage}>
                <Icon name="checkJagged" />
                {t('offerings.advantage.platformCost', 'Platformkost aan € {{ price }} / maand', {
                  price: formatAmount(PRODUCTS_CONFIG[Product.GO][ProductConfigOption.PLATFORM_COST][CustomerType.RESIDENTIAL])
                })}
              </li>
            </ul>

            <hr />

            <div>
              <Heading as="h4" variant="h6" className="mb-200">
                {t('offerings.go.subtitle', 'Jaarlijks voorschot')}
              </Heading>
              <p>
                {t(
                  'offerings.go.description',
                  'Dit bedrag is een vast, eenmalig voorschot. Na je eerstvolgende afrekening zal dit aangepast worden aan je werkelijk verbruik en de marktprijzen voor energie.'
                )}
              </p>
            </div>

            <div className={styles.estimations}>
              <div className={styles.estimation}>
                <Electricity /> <data value={2500}>€ 1500</data>
              </div>

              {needsGas && (
                <div className={styles.estimation}>
                  <Gas /> <data value={2500}>€ 2500</data>
                </div>
              )}
            </div>

            <Button onClick={() => startRegistration(Product.GO)}>
              {t('offerings.cta', 'Stap over met Bolt {{ product }}', {
                product: productsContent?.[Product.GO]?.name
              })}
            </Button>
          </div>
        )}
      </div>
    </section>
  )
}

export default OfferingSection
