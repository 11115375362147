import classNames from 'classnames'
import styles from 'features/bolt-go/BoltGo.module.scss'
import parse from 'html-react-parser'
import { Button } from '@boltenergy-be/design-system'
import type { SectionProps } from 'features/bolt-go/types'
import { useTranslation } from 'next-i18next'
import { Product } from 'types/product-data'
import Img from 'components/img/Img'
import { Heading } from '@boltenergy-be/design-system'

const InfoSection = ({ isDark, startRegistration }: SectionProps) => {
  // i18n
  const { t } = useTranslation('boltGo')

  return (
    <section id="info-section" className={classNames('section', { ['is-dark']: isDark }, styles['info-section'])}>
      <div className={classNames('container', styles['info-container'])}>
        <div className={styles.info}>
          <Heading as="h2" variant="h3" className="mb-600">
            {t('info.title', 'Bolt Go voor jou uitgelegd')}
          </Heading>
          <Heading as="h3" variant="h6" className="mb-300">
            {t('info.instalment.title', 'Een jaarlijks, éénmalig voorschot')}
          </Heading>
          <p>
            {parse(
              t(
                'info.instalment.description',
                'Omdat we nog niet weten hoeveel energie jij in de toekomst zal verbruiken, betaal je een voorschot. Het bedrag van je voorschot is gewoonlijk een inschatting op basis van je verbruik en de marktprijzen voor energie. In plaats van maandelijkse voorschotten, betaal je bij Bolt Go slechts één voorschot tussen verschillende afrekeningen.'
              )
            )}
          </p>

          <Heading as="h3" variant="h6" className="mb-300 mt-500">
            {t('info.custom.title', 'Van een forfaitair voorschotbedrag naar een bedrag op maat')}
          </Heading>
          <p>
            {t(
              'info.custom.description',
              'Als je start met Bolt Go, bedraagt je eerste voorschot €1500 voor elektriciteit en/of €2500 voor gas. Na je eerste afrekening zal het bedrag van je éénmalig voorschot ingeschat worden volgens jouw verbruik.'
            )}
          </p>
        </div>

        <div className={classNames(styles['image-wrapper'], styles.right)}>
          <picture>
            <Img
              publicId="website/bolt-go/kris-inside-house"
              alt=""
              fill
              sizes="(min-width: 920px) 50vw, 100vw"
              objectFit="contain"
              objectPosition="top"
            />
          </picture>
        </div>

        <div className={styles['image-wrapper']}>
          <picture>
            <Img
              publicId="website/bolt-go/producer-inside-house"
              fill
              objectFit="contain"
              objectPosition="top"
              alt=""
              sizes="(min-width: 920px) 50vw, 100vw"
            />
          </picture>
        </div>

        <div className={styles.info}>
          <Heading as="h3" variant="h6" className="mb-300">
            {t('info.settlement.title', 'Bolt Go heeft geen invloed op je afrekening')}
          </Heading>
          <p>
            {parse(
              t(
                'info.settlement.description',
                'Net zoals bij onze andere tarieven, en bij andere leveranciers, ontvang je een afrekening rond de periode van de opname van de meterstanden door de netbeheerder. Op je afrekening zal je geld terug krijgen of bij moeten betalen, naargelang je vastgesteld verbruik.'
              )
            )}
          </p>

          <Heading as="h3" variant="h6" className="mb-300 mt-600">
            {t('info.platformCost.title', 'Een platformkost van €0,99')}
          </Heading>
          <p>
            {parse(
              t(
                'info.platformCost.description',
                'Bij Bolt weet je waar je geld naartoe gaat. Net zoals met <a rel="noreferrer noopener" href="/nl/tarief">onze andere tarieven</a> geniet je met Bolt Go van een eenvoudig variabel tarief voor lokale, duurzame stroom. Het enige verschil is de lage platformkost aan Bolt van €0,99 voor elektriciteit of gas. Deze kost is laag doordat we minder administratief werk hebben, doordat we jou slechts één voorschot per afrekeningsperiode hoeven sturen. Daarnaast help je ons ook met prefinanciering.'
              )
            )}
          </p>
          <Button onClick={() => startRegistration(Product.GO)} className="mt-400">
            {t('info.cta', 'Stap over met Bolt Go')}
          </Button>
        </div>
      </div>
    </section>
  )
}

export default InfoSection
