import { FC } from 'react'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Layout from 'components/layout/Layout'
import { BoltGoProps as Props } from 'features/bolt-go/types'
import { fetchProducers } from 'api/producer'
import { DEFAULT_NAMESPACES, PRODUCERS_NAMESPACES } from 'constants/i18n'
import BoltGo from 'features/bolt-go/BoltGo'
import { stringify } from 'qs'
import { faqCategoriesSlugs } from 'constants/faq-categories'
import { FAQCategories } from 'features/faq-page/types'
import { getFaqQuestions } from 'api/cms/faq'
import { filterAndSortProducers } from 'utils/producers'

const BoltGoPage: FC<Props> = (props) => {
  return (
    <Layout>
      <BoltGo {...props} />
    </Layout>
  )
}

export const getStaticProps = async ({ locale }) => {
  const questionsQuery = stringify({
    filters: {
      categories: {
        slug: {
          $in: faqCategoriesSlugs[FAQCategories.BOLT_GO][locale]
        }
      }
    },
    locale
  })

  const [producers, questions] = await Promise.all([
    await fetchProducers({ language: locale, params: { enabled: true, soldOut: false } }),
    await getFaqQuestions(questionsQuery)
  ])

  return {
    props: {
      producers: filterAndSortProducers({ hideSoldOut: true, language: locale, producers }).slice(0, 4),
      questions,
      ...(await serverSideTranslations(locale, [...DEFAULT_NAMESPACES, 'boltGo', 'simulation', ...PRODUCERS_NAMESPACES]))
    },
    revalidate: 600 // re-generate page when a request comes in, once every 10 minutes
  }
}

export default BoltGoPage
