import { FC, useEffect } from 'react'
import styles from './BoltGo.module.scss'
import classNames from 'classnames'
import { Button } from '@boltenergy-be/design-system'
import { useTranslation } from 'next-i18next'
import Icon from 'components/icon/Icon'
import { BoltGoProps as Props, SessionStorageKeys } from 'features/bolt-go/types'
import OfferingSection from 'features/bolt-go/sections/OfferingSection'
import InfoSection from 'features/bolt-go/sections/InfoSection'
import Tag from 'components/tag/Tag'
import { Star } from 'assets/svg'
import ProducerCard from 'components/producers/producer-card/ProducerCard'
import { BOLT_GO_PRICES } from 'constants/customerFlow'
import router from 'next/router'
import { selectProposition, setProductForSpecificSimulation, updateBoltGoPrices, updateInput } from 'store/customer/slice'
import { SimulationType } from 'store/marketing/types'
import { Product } from 'types/product-data'
import { routes } from 'utils/routes'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import mixpanel from 'mixpanel-browser'
import { BOLT_GO_TRACKING } from 'constants/tracking'
import FaqSection from 'components/faq/faq-section/FaqSection'
import Hero from 'components/sections/hero/Hero'
import { OFFERING_CARDS } from 'constants/offering'
import { Heading } from '@boltenergy-be/design-system'
import { InputGroupKeys, SimulationFieldKeys } from 'store/customer/enums'

const BoltGo: FC<Props> = ({ producers, questions }) => {
  // Redux
  const { inputs } = useStoreSelector((store) => store.customer)
  const { personalData } = inputs
  const dispatch = useStoreDispatch()

  // i18n
  const { t } = useTranslation(['boltGo', 'common'])

  /**
   * Trigger on page load, track page view
   */
  useEffect(() => {
    mixpanel.track(BOLT_GO_TRACKING.OPENED)
  }, [])

  /**
   * Start registration based on product
   * @param {Product} product
   */
  const startRegistration = (product: Product) => {
    const isBoltGo = product === Product.GO

    sessionStorage.setItem(SessionStorageKeys.FROM, 'bolt-go-landing')
    sessionStorage.setItem(SessionStorageKeys.PRODUCT, product)

    // Set the product
    dispatch(selectProposition({ productType: product }))

    // Set the simulation type
    dispatch(
      updateInput({
        group: InputGroupKeys.SIMULATION,
        key: SimulationFieldKeys.CHOSEN_SIMULATION_TYPE,
        value: isBoltGo ? SimulationType.BOLT_GO : SimulationType.EXPRESS
      })
    )

    if (!isBoltGo) {
      // Select a specific product to simulate
      dispatch(setProductForSpecificSimulation(product))
    }

    // Set the bolt go prices if necessary
    if (isBoltGo)
      dispatch(updateBoltGoPrices({ electricity: BOLT_GO_PRICES.electricity, gas: personalData.needsGas ? BOLT_GO_PRICES.gas : 0 }))

    return router.push(routes(router.locale).registrationLoading)
  }

  return (
    <main className={styles['bolt-go']}>
      {/* HERO */}
      <Hero image={{ alt: '', publicId: 'website/producers/zonnewind' }}>
        <Tag className={styles.tag}>
          <Star /> {t('hero.tag', '5/5 VREG Servicecheck')}
        </Tag>
        <Hero.Title value={t('hero.title', 'Bolt Go, een jaarlijks voorschot voor duurzame stroom')} />
        <Hero.Paragraph
          value={t(
            'hero.description',
            'Bolt is het energieplatform dat het Belgische stroomnet echt duurzaam wil maken. Via Bolt kan je jouw energie rechtstreeks kopen van lokale, groene opwekkers. Zo weet jij exact waar je energie vandaan komt en waar je geld naartoe gaat. Bolt Go biedt je de mogelijkheid om slecht één keer per jaar een voorschot te betalen in plaats van maandelijks.'
          )}
        />
        <Button
          className={styles['hero-btn']}
          onClick={() => {
            const scrollTo = document.getElementById('offering-section')
            scrollTo?.scrollIntoView({ behavior: 'smooth' })
          }}
        >
          {t('hero.cta', 'Welk tarief past bij jou?')}
        </Button>
      </Hero>

      {/* VALUES */}
      <section>
        <ul className={styles.usps}>
          {OFFERING_CARDS.map((card) => (
            <li key={card.key} className={styles.usp}>
              <Icon name="checkJagged" />
              {t(`common:offering.${card.key}.tag`, card.placeholders.tag)}
            </li>
          ))}
        </ul>
      </section>

      {/* OFFERING */}
      <OfferingSection {...{ startRegistration }} />

      {/* INFO*/}
      <InfoSection isDark {...{ startRegistration }} />

      {/* PRODUCERS */}
      <section className={classNames('section', styles['producers-section'])}>
        <div className="container">
          <div className={styles.intro}>
            <Heading as="h2" variant="h3">
              {t('producers.title', 'Energie van Bolt, dat is groene energie uit jouw buurt')}
            </Heading>
            <p>
              {t(
                'producers.description',
                'Via Bolt koop je jouw energie rechtstreeks aan van lokale, groene opwekkers. Zo weet jij exact waar je energie vandaan komt en waar je geld naartoe gaat.'
              )}
            </p>
          </div>
        </div>

        <div className={classNames('container', styles['scrollable-wrapper'])}>
          <ul className={styles.producers}>
            {producers.map((producer) => (
              <li key={producer.id}>
                <ProducerCard producer={producer} showCTA />
              </li>
            ))}
          </ul>
        </div>
      </section>

      {/* FAQ */}
      <FaqSection questions={questions} title={t('faq.title', 'Veelgestelde vragen over Bolt Go')} />
    </main>
  )
}

export default BoltGo
