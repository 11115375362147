import classNames from 'classnames'
import styles from './Hero.module.scss'
import SplitSection from 'components/split-section/SplitSection'
import { PropsWithChildren, ReactNode } from 'react'
import { HeroProps } from './types'
import StartSimulationForm from 'features/home/start-simulation-form/StartSimulationForm'
import Img from 'components/img/Img'
import { Heading } from '@boltenergy-be/design-system'
import { BackgroundColors } from 'types/layout'

const Title = ({ value, className }: { value: string | ReactNode; className?: string }) => {
  return (
    <Heading as="h1" variant="h2" className={classNames(styles.title, className)}>
      {value}
    </Heading>
  )
}

const Paragraph = ({ value, className }: { value: string | ReactNode; className?: string }) => {
  return <p className={className}>{value}</p>
}

const CtaGroup = ({ children }: { children: ReactNode }) => {
  return <div className={styles['hero-cta-group']}>{children}</div>
}

const Hero = ({
  children,
  image,
  withSimulationForm,
  specificProductSimulation,
  className,
  backgroundColor = BackgroundColors.SUBDUED,
  absoluteBottomSvg
}: PropsWithChildren<HeroProps>) => {
  return image ? (
    <SplitSection
      id="hero"
      className={classNames(styles['split-hero'], styles[backgroundColor], { [styles['with-form']]: withSimulationForm }, className)}
    >
      <SplitSection.Left className={styles['hero-content']}>{children}</SplitSection.Left>
      <SplitSection.Right className={styles.right}>
        <figure className={styles.figure}>
          <Img
            fill
            priority
            publicId={image.publicId}
            alt={image.alt}
            sizes="(min-width: 920px) 50vw, 100vw"
            className={image.customClass || ''}
          />
          {image.caption && (
            <figcaption>
              <strong>{image.caption.text}</strong>
              {image.caption.extra && <small>{image.caption.extra}</small>}
            </figcaption>
          )}
        </figure>
      </SplitSection.Right>

      {absoluteBottomSvg}

      {withSimulationForm && (
        <div className={classNames('container', styles['form-container'])}>
          <StartSimulationForm specificProduct={specificProductSimulation} className={styles['simulation-form']} />
        </div>
      )}
    </SplitSection>
  ) : (
    <section className={classNames(styles.hero, styles[backgroundColor], 'container', className)}>{children}</section>
  )
}

Hero.Title = Title
Hero.Paragraph = Paragraph
Hero.CtaGroup = CtaGroup

export default Hero
